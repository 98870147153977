import React from 'react';
import { graphql } from 'gatsby';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import Layout from '../src/components/Layout/Layout';
import Hod from '../src/components/Hod/Hod';
import SEO from '../src/helpers/seo';
import Buttons from '../src/components/Buttons/Buttons';
import tt from '../src/helpers/translation';

import './indoor.scss';

export const GatsbyQuery = ({ data, pathContext: { locale } }) => graphql`
  query indoor($locale: String!) {
    allIndoorMainText(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          text
        }
      }
    }
    allIndoorSeoData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allIndoorsBreadcrumbBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          name
        }
      }
    }
    allIndoorsRichData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            og_title
            rich_markup_type
            og_description
            imgRich
          }
        }
      }
    }
  }
`;

class Indoor extends React.Component {
  state = {};

  render() {
    return (
      <Layout layoutFromOld  locale={this.props.pageContext.locale} pageName={this.props.pageContext.pageName}>
        <SEO
          title={this.props.data.allIndoorSeoData.edges[0].node._0.title}
          description={this.props.data.allIndoorSeoData.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
          imageRich={this.props.data.allIndoorsRichData.edges[0].node.rich_markup.imgRich}
        />
        <div className="is-fullWidth indoor">
          <NeoAesir
            locale={this.props.pageContext.locale}
            pageName={this.props.pageContext.pageName}
            title={this.props.data.allIndoorsBreadcrumbBlock.edges[0].node.name}
          />
          <Hod
            data={this.props.data.allIndoorsRichData.edges[0].node.rich_markup}
            url={this.props.pageContext.url}
            img={this.props.data.allIndoorsRichData.edges[0].node.rich_markup.imgRich}
          />
          <div
            className="indoor-text"
            dangerouslySetInnerHTML={{
              __html: this.props.data.allIndoorMainText.edges[0].node.text,
            }}
          />
          <Buttons
            link="distributiva_actividades"
            size="alone"
            color="orange"
            text={tt('VER EXPERIENCIAS', this.props.pageContext.locale)}
            named
          />
        </div>
      </Layout>
    );
  }
}

export default Indoor;
